import Cookies from 'js-cookie';
import {Base64} from 'js-base64';

let env = 'stg';

const STG_DEV = 'stg';

//sso loging from d+stg env to stg url (configured in Okta stagging)
const stgURL = 'https://stg.sso.dnb.com/oauth2/auscaeiz0DjykC5321d6/v1/authorize?client_id=0oag4dhazwZq17pGa1d7&nonce=lgPBIRJVzhnP4XOUL&redirect_uri=https%3A%2F%2Fstg.directplus.documentation.dnb.com/home.html&response_type=id_token&scope=openid&state=8ihuwPs2u9uJIAPoQjBRs92HR3';

const prodURL = 'https://sso.dnb.com/oauth2/ausjte6yg4e6fzj4K5d6/v1/authorize?client_id=0oajnbs8rsgIFRKO25d7&nonce=lgPBIRJVzhnP4XOUL&redirect_uri=https%3A%2F%2Fdirectplus.documentation.dnb.com/home.html&response_type=id_token&scope=openid&state=8ihuwPs2u9uJIAPoQjBRs92HR3';

//sso loging from d+stg env to stg url (configured in Okta prod)
//const authURL = 'https://sso.dnb.com/oauth2/ausjte6yg4e6fzj4K5d6/v1/authorize?client_id=0oa1g3n7scX5hF5d25d7&nonce=c14903a0ea228e04d1e8f9c8576f9915&redirect_uri=https%3A%2F%2Fstg.directplus.documentation.dnb.com&response_type=id_token&scope=openid&state=b98c9667067916ec56bafd3f4150033';
let authURL = '';


let oneLoginReturn = false;

//window.onload = function () {
function main() {
	
	if (env === 'stg') {
		authURL = stgURL
	} else if (env === 'prod') {
		authURL = prodURL
	} else {
		console.log('no security required');
		return;
	}
/*	
  if (env != STG_DEV) {
    console.log('no security required');
    return;
  }
*/
  console.log('security required');

// check for the presence of the relevant cookie
// if present, continue
  let cookie = Cookies.get('dnbstgdocs');

  if (!cookie) {
    //check for return from OneLogin
    let url = window.location;
    if (url.href.indexOf('#') > -1) {
      let anchor2 = url.hash.substring(1);
      let params = getParams(anchor2);

      if(!params.hasOwnProperty("id_token")){
        console.log('authentication required');
        // if not present, authenticate to OneLogin
        window.location.href = authURL;
        return;      
      }
      
      let payload = Base64.decode(params.payload);
      //console.log(payload);
      oneLoginReturn = true;
      let cookieVal = payload.email + '-' + payload.exp;
      // set a cookie with an expiry of 1 day
      Cookies.set('dnbstgdocs', cookieVal, {expires: 1});
    }
  }

  if (!cookie && !oneLoginReturn) {
    console.log('authentication required');
    // if not present, authenticate to OneLogin
    window.location.href = authURL;
    return;
  }
}

//execute main function
main();

function getParams(params) {
  var assoc = {};
  //var queryString = params.substring(1);
  var queryString = params;
  var keyValues = queryString.split('&');
  for (var i in keyValues) {
    var key = keyValues[i].split('=');
    if (key.length > 1) {
      assoc[key[0]] = key[1];
      if (key[1].indexOf('.') > -1) {
        var idtokenvalues = key[1].split('.');
        assoc["jose_header"] = idtokenvalues[0];
        assoc["payload"] = idtokenvalues[1];
        assoc["signature"] = idtokenvalues[2];
      }
    }
  }
  return assoc;
}




